import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const SixthSection = () => {
    const data=useStaticQuery(graphql`
    query SixthSectionQuery {
        strapiEssentialGuide {
          Section6Header
          Section6Paragraph
          Section6Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section
            header={data?.strapiEssentialGuide?.Section6Header}
            paragraph={data?.strapiEssentialGuide?.Section6Paragraph}
            image={data?.strapiEssentialGuide?.Section6Image.localFile.publicURL}
            side={"left"}/>
        </div>
    )
}

export default SixthSection
