import React from 'react'
import "./jumbotronBackground.less"

const JumbotronBackground = (props) => {
    return (
        <div className="jumbotron-background">
            {props.children}
        </div>
    )
}

export default JumbotronBackground
