import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Button, Col, Container, Row } from "react-bootstrap"
import "./Jumbotron.less"

const Jumbotron = () => {
  const data = useStaticQuery(graphql`
    query GuideJumbotronQuery {
      strapiEssentialGuide {
        MainTitle
        MainDescription
        CallToActionButtonText
        CallToActionButtonUrl
      }
    }
  `)

  return (
    <Container className="jumbotron-container">
      <Row>
        <Col md={10}>
          <h1 className="display-1">{data?.strapiEssentialGuide?.MainTitle}</h1>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <p className="h6">{data?.strapiEssentialGuide?.MainDescription}</p>
        </Col>
      </Row>
      <Row>
        <Col md={10}>
          <a href={data?.strapiEssentialGuide?.CallToActionButtonUrl} className=" btn cta-btn">{data?.strapiEssentialGuide?.CallToActionButtonText}</a>
        </Col>
      </Row>
    </Container>
  )
}

export default Jumbotron
