import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Image, Row, Col } from "react-bootstrap"
import "./Card.less"

const Cards = () => {
  const data = useStaticQuery(graphql`
    query GuideCardsQuery {
      strapiEssentialGuide {
        DescriptionCard1 {
          BackgroundColor
          Description
          Image {
            localFile {
              publicURL
            }
          }
        }
        DescriptionCard2 {
          BackgroundColor
          Description
          Image {
            localFile {
              publicURL
            }
          }
        }
        DescriptionCard3 {
          BackgroundColor
          Description
          Image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)

  console.log(Object.values(data?.strapiEssentialGuide))
  return (
    <Container className="guide-cards-container">
      <Row>
        {Object.values(data?.strapiEssentialGuide).map(item => (
          <Col md={4}>
            <div className="description-card" style={{ backgroundColor: `${item.BackgroundColor}` }}>
              <Image src={item.Image.localFile.publicURL}></Image>
              <p className="h6">{item.Description}</p>
            </div>
          </Col>
        ))}
      </Row>
    </Container>
  )
}

export default Cards
