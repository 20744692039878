import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const ThirdSection = () => {
    const data=useStaticQuery(graphql`
    query ThirdSectionQuery {
        strapiEssentialGuide {
          Section3Header
          Section3Paragraph
          Section3Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section 
            header={data?.strapiEssentialGuide?.Section3Header}
            paragraph={data?.strapiEssentialGuide?.Section3Paragraph}
            image={data?.strapiEssentialGuide?.Section3Image.localFile.publicURL}
            side={"right"}/>
        </div>
    )
}

export default ThirdSection
