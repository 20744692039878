import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const FirstSection = () => {
    const data=useStaticQuery(graphql`
    query FirstSectionQuery {
        strapiEssentialGuide {
          Section1Header
          Section1Paragraph
          Section1Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section 
            header={data?.strapiEssentialGuide?.Section1Header}
            paragraph={data?.strapiEssentialGuide?.Section1Paragraph}
            image={data?.strapiEssentialGuide?.Section1Image.localFile.publicURL}
            side={"right"}/>
        </div>
    )
}

export default FirstSection
