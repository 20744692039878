import React from 'react'
import Jumbotron from '../components/essentialGuide/jumbotron/Jumbotron'
import FirstSection from '../components/essentialGuide/sections/FirstSection'
import SecondSection from '../components/essentialGuide/sections/SecondSection'
import ThirdSection from '../components/essentialGuide/sections/ThirdSection'
import FourthSection from '../components/essentialGuide/sections/FourthSection'
import FifthSection from '../components/essentialGuide/sections/FifthSection'
import SixthSection from '../components/essentialGuide/sections/SixthSection'
import NavigationBar from '../components/navbar/navigationBar'
import JumbotronBackground from '../components/essentialGuide/jumbotronBackground/JumbotronBackground'
import "./essentialguide.less"
import WalletTypes from '../components/essentialGuide/wallettypes/WalletTypes'
import Footer from '../components/footer/footer'
import AccessInfo from '../components/essentialGuide/accessinfo/AccessInfo'
import Cards from '../components/essentialGuide/cards/Cards'

const essentialguide = () => {
    return (
        <div className="walletpage-container">
            <JumbotronBackground>
                <NavigationBar/>
                <Jumbotron/>
            </JumbotronBackground>
            <FirstSection/>
            <SecondSection/>
            <ThirdSection/>
            <WalletTypes/>
            <FourthSection/>
            <FifthSection/>
            <SixthSection/>
            <Cards/>
            <AccessInfo/>
            <Footer/>
        </div>
    )
}

export default essentialguide
