import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const FourthSection = () => {
    const data=useStaticQuery(graphql`
    query FourthSectionQuery {
        strapiEssentialGuide {
          Section4Header
          Section4Paragraph
          Section4Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section 
            header={data?.strapiEssentialGuide?.Section4Header}
            paragraph={data?.strapiEssentialGuide?.Section4Paragraph}
            image={data?.strapiEssentialGuide?.Section4Image.localFile.publicURL}
            side={"left"}/>
        </div>
    )
}

export default FourthSection
