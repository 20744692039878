import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const FifthSection = () => {
    const data=useStaticQuery(graphql`
    query FifthSectionQuery {
        strapiEssentialGuide {
          Section5Header
          Section5Paragraph
          Section5Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section
            header={data?.strapiEssentialGuide?.Section5Header}
            paragraph={data?.strapiEssentialGuide?.Section5Paragraph}
            image={data?.strapiEssentialGuide?.Section5Image.localFile.publicURL}
            side={"right"}/>
        </div>
    )
}

export default FifthSection
