import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import ReactMarkdown from "react-markdown"
import "./Section.less"

const Section = props => {
  const { header, paragraph, side, image } = props
  return (
    <div className="section-bg">
        <Container className="section-container">
      <Row>
        <Col className="d-flex flex-column justify-content-center" sm={6}>
          {side === "left" ? (
            <div>
              <h2 className="h1">{header}</h2>
              <ReactMarkdown className="h6">{paragraph}</ReactMarkdown>
            </div>
          ) : (
            <Image src={image} fluid/>
          )}
        </Col>
        <Col className="d-flex d-flex flex-column justify-content-center" sm={6}>
          {side === "right" ? (
            <div>
              <h2 className="h1">{header}</h2>
              <ReactMarkdown className="h6">{paragraph}</ReactMarkdown>
            </div>
          ) : (
            <Image src={image} fluid/>
          )}
        </Col>
      </Row>
    </Container>
    </div>  
    
  )
}

export default Section
