import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import "./WalletTypes.less"

const WalletTypes = () => {
  const data = useStaticQuery(graphql`
    query WalletTypesQuery {
      strapiEssentialGuide {
        WalletType1 {
          BackgroundColor
          Paragraph
          Title
          Icon {
            localFile {
              publicURL
            }
          }
        }
        WalletType2 {
          BackgroundColor
          Title
          Paragraph
          Icon {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  `)
    const bgColor1=data?.strapiEssentialGuide?.WalletType1.BackgroundColor;
    const bgColor2=data?.strapiEssentialGuide?.WalletType2.BackgroundColor;
  return (
    <Container className="wallettypes-container">
      <Row>
        <Col className="hot-wallets" md={6}>
          <div
            className="hot-wallets-box wallet-box"
            style={{ backgroundColor: `${bgColor1}` }}
          >
            <Image src={data?.strapiEssentialGuide?.WalletType1.Icon.localFile.publicURL} />
            <p className="title">{data?.strapiEssentialGuide?.WalletType1.Title}</p>
            <p className="h6">{data?.strapiEssentialGuide.WalletType1.Paragraph}</p>
          </div>
        </Col>
        <Col className="cold-wallets" md={6}>
          <div
            className="cold-wallets-box wallet-box"
            style={{ backgroundColor: `${bgColor2}` }}
          >
            <Image src={data?.strapiEssentialGuide?.WalletType2.Icon.localFile.publicURL} />
            <p className="title">{data?.strapiEssentialGuide?.WalletType2.Title}</p>
            <p className="h6">{data?.strapiEssentialGuide.WalletType2.Paragraph}</p>
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default WalletTypes
