import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { Container, Button, Row, Col } from "react-bootstrap"
import "./AccessInfo.less"

const AccessInfo = () => {
  const data = useStaticQuery(graphql`
    query AccessInfoQuery {
      strapiEssentialGuide {
        ExtraWarning
        AccessInfoHeader
        AccessInfoParagraph
        CallToActionButtonText
        CallToActionButtonUrl
      }
    }
  `)

  return (
    <div className="bottom-bg-image">
      <Container className="access-info-container">
        <Row>
          <Col>
            <p className="h6">{data?.strapiEssentialGuide?.ExtraWarning}</p>
            <h6>{data?.strapiEssentialGuide?.AccessInfoHeader}</h6>
            <p className="h6">{data?.strapiEssentialGuide?.AccessInfoParagraph}</p>
            <a href={data?.strapiEssentialGuide?.CallToActionButtonUrl} className="btn cta-button">
              {data?.strapiEssentialGuide?.CallToActionButtonText}
            </a>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default AccessInfo
