import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import Section from './Section';


const SecondSection = () => {
    const data=useStaticQuery(graphql`
    query SecondSectionQuery {
        strapiEssentialGuide {
          Section2Header
          Section2Paragraph
          Section2Image{
            localFile{
              publicURL
            }
          }
        }
      }
      
    `);

    return (
        <div>
            <Section 
            header={data?.strapiEssentialGuide?.Section2Header}
            paragraph={data?.strapiEssentialGuide?.Section2Paragraph}
            image={data?.strapiEssentialGuide?.Section2Image?.localFile.publicURL}
            side={"left"}/>
        </div>
    )
}

export default SecondSection;
